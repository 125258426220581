import tingle from 'tingle.js';

export default class Modals {
  constructor() {
    this.getElements();
    this.bindEvents();

    this.modal = new tingle.modal({
      beforeClose: () => {
        document.body.classList.add('tingle-exiting');
        setTimeout(() => document.body.classList.remove('tingle-exiting'), 500);
        return true;
      }
    });
  }

  getElements() {
    this.disclaimer = document.querySelector('.section--disclaimer');
    this.disclaimerBtn = document.querySelector('[data-modal="disclaimer"]');

    this.privacyPolicy = document.querySelector('.section--privacy-policy');
    this.privacyPolicyBtn = document.querySelector(
      '[data-modal="privacy-policy"]'
    );
  }

  bindEvents() {
    this.disclaimerBtn.addEventListener('click', () => this.toggleDisclaimer());
    this.privacyPolicyBtn.addEventListener('click', () =>
      this.togglePrivacyPolicy()
    );
  }

  bindCloseButton() {
    this.closeButton = this.modal.modal.querySelector('.modal--close');
    this.closeButton.addEventListener('click', () => this.modal.close());
  }

  toggleDisclaimer() {
    this.modal.setContent(this.disclaimer.innerHTML);
    this.modal.open();

    this.bindCloseButton();
  }

  togglePrivacyPolicy() {
    this.modal.setContent(this.privacyPolicy.innerHTML);
    this.modal.open();

    this.bindCloseButton();
  }
}


// // MODAL NEW
// // Get the modal
// var modal = document.getElementById('video-teaser');

// // Get the button that opens the modal
// var btn = document.getElementById("myBtn");

// // Get the <span> element that closes the modal
// var span = document.getElementsByClassName("modal-close")[0];

// // When the user clicks the button, open the modal 
// btn.onclick = function() {
//   modal.style.display = "block";
// }

// // When the user clicks on <span> (x), close the modal
// span.onclick = function() {
//   modal.style.display = "none";
// }

// // When the user clicks anywhere outside of the modal, close it
// window.onclick = function(event) {
//   if (event.target == modal) {
//     modal.style.display = "none";
//   }
// }