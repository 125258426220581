import anime from 'animejs';
import _throttle from 'lodash/throttle';

export default class Fullpage {
  constructor() {
    document.body.classList.add('fullpage--active');

    this.getElements();
    this.defineProps();
    this.bindEvents();
  }

  getElements() {
    // get sections
    this.sections = document.querySelectorAll('.section');

    // set z-index to fix overlapping
    this.sections.forEach(
      (section, index) => (section.style.zIndex = index + 10)
    );
  }

  defineProps() {
    this.animating = false;
    if (!this.currentIndex) this.currentIndex = 0;
    this.stopPoints = [];

    this.callbacks = [];
  }

  bindEvents() {
    // bind wheel event
    window.addEventListener(
      'wheel',
      _throttle(this.wheelEvent.bind(this), 200)
    );

    window.addEventListener(
      'resize',
      _throttle(this.resizeEvent.bind(this), 16)
    );
  }

  wheelEvent(e) {
    // check direction
    this.direction = e.deltaY < 0 ? 'up' : 'down';

    // check if current index is a stop point
    let isStopPoint;
    this.stopPoints.forEach(point => {
      if (this.currentIndex === point[0] && this.direction === point[1])
        isStopPoint = true;
    });

    // check if required to skip animation
    const modalActive = document.body.classList.contains('tingle-enabled');

    const stopAnim =
      this.animating ||
      isStopPoint ||
      modalActive;
    if (stopAnim) return;

    // increment / descrement current Index based on direction and animate
    if (
      this.direction === 'down' &&
      this.currentIndex < this.sections.length - 1
    ) {
      this.currentIndex++;
      this.goTo(this.currentIndex);
    } else if (this.direction === 'up' && this.currentIndex !== 0) {
      this.currentIndex--;
      this.goTo(this.currentIndex);
    }
  }

  resizeEvent() {
    const currentSection = this.sections[this.currentIndex];
    const currentY = currentSection.offsetTop;
    window.scrollTo(0, currentY);
  }

  goTo(index) {
    this.currentIndex = index;
    this.animateScroll();
  }

  goToNext() {
    this.direction = 'down';
    if (this.currentIndex === this.sections.length - 1) return;

    this.currentIndex++;
    this.animateScroll();
  }

  goToPrev() {
    this.direction = 'up';
    if (this.currentIndex === 0) return;

    this.currentIndex--;
    this.animateScroll();
  }
/* SCROLL PAGE */
/* ----------------------------*/ 
  animateScroll(duration = 800) {
    // remove from animation queue
    anime.remove(this.sections);
    // reset transforms
    this.sections.forEach(section => (section.style.transform = ''));

    // get scroll position
    const currentScroll = window.scrollY || window.pageYOffset;
    const scrollPos = { val: currentScroll };
    const newScrollPos = this.sections[this.currentIndex].offsetTop;

    // create animation timeline
    const animTimeline = anime.timeline({
      easing: 'easeInOutCubic',
      duration: duration
    });

    animTimeline.add({
      targets: scrollPos,
      val: newScrollPos,

      begin: () => (this.animating = true),
      update: () => window.scrollTo(0, scrollPos.val),
      complete: () => {
        this.animating = false;
        this.executeCallbacks();

        this.updateURL();
      }
    });

    // if direction is down, animate previous section
    if (this.direction === 'down') {
      animTimeline.add({
        targets: this.sections[this.currentIndex - 1],
        translateY: ['0', '75%'],
        offset: 0
      });
      // else if direction is up, animate next section
    } else {
      animTimeline.add({
        targets: this.sections[this.currentIndex],
        translateY: ['75%', '0'],
        offset: 0
      });
    }
  }

/* AUTOMATIC URL UPDATE*/
/* ----------------------------*/ 
  updateURL() {
    if (this.currentIndex === 0)
      window.history.replaceState(null, null, window.location.origin);
    else
      window.history.replaceState(
        null,
        null,
        `${window.location.origin}#section${this.currentIndex}`
      );
  }

  setStart() {
    const newIndex = window.location.hash.split('').pop();
    this.currentIndex = newIndex;
    this.animateScroll(0);
  }

  toggleExec(state) {
    if (state === 'pause') document.body.classList.add('fullpage--paused');
    if (state === 'unpause') document.body.classList.remove('fullpage--paused');
  }

  executeCallbacks() {
    this.callbacks.forEach(cb => {
      if (this.currentIndex === cb[0]) cb[1]();
    });
  }

  bindCallback(index, callback) {
    this.callbacks.push([index, callback]);
  }

  onEverySlide(callback) {
    for (let i = 0; i < this.sections.length; i++)
      this.callbacks.push([i, callback]);
  }

  setStopPoint(index, direction) {
    this.stopPoints.push([index, direction]);
  }
}
