import throttle from 'lodash/throttle';

import anime from 'animejs';
import SVG from 'svg.js';
import 'svg.easing.js';

export class Animator {
  constructor() {
    this.animateLoop();
  }

  animateLoop() {
    // Get elements
    this.promptEl = document.querySelector('.prompt--next');
    this.promptBoxEl = this.promptEl.querySelector('.box');
    this.promptLineEl = this.promptEl.querySelector('.line');
    this.promptBorderEl = this.promptEl.querySelector('.border');

    // Pass them to SVG.js
    this.prompt = SVG.adopt(this.promptEl);
    this.promptBox = SVG.adopt(this.promptBoxEl);
    this.promptLine = SVG.adopt(this.promptLineEl);
    this.promptBorder = SVG.adopt(this.promptBorderEl);

    // create repeat animations
    this.promptLine
      .animate(1600, '-')
      .attr({ 'stroke-dashoffset': 32 })
      .reverse()
      .loop();
    this.promptBorder
      .animate(1200, '-')
      .attr({ 'stroke-dashoffset': 30 })
      .loop();

    this.bindHoverEvent();
  }

  bindHoverEvent() {
    this.enterEvent = () => this.hoverEvent(1.4);
    this.leaveEvent = () => this.hoverEvent(1.0);

    this.promptBoxEl.addEventListener('mouseenter', this.enterEvent);
    this.promptBoxEl.addEventListener('mouseleave', this.leaveEvent);
  }

  unbindHoverEvent() {
    this.promptBoxEl.removeEventListener('mouseenter', this.enterEvent);
    this.promptBoxEl.removeEventListener('mouseleave', this.leaveEvent);
  }

  hoverEvent(value) {
    this.promptBox.stop();
    this.promptBox.animate(400, 'quartOut').scale(value, value);
  }

  createPromptNextEvent() {
    const $this = this;

    this.scrollEvent = throttle(ev, 200);
    window.addEventListener('wheel', this.scrollEvent);

    function ev(e) {
      const direction = e.deltaY < 0 ? 'up' : 'down';
      if (direction === 'up') {
        $this.unbindEvent();
        return;
      }

      if (document.body.classList.contains('modal--registration--active'))
        return;

      if (typeof $this.promptNextEvent === 'function') $this.animateSlide();
    }
  }

  unbindEvent() {
    window.removeEventListener('wheel', this.scrollEvent);
  }

  animateSlide() {
    this.unbindEvent();
    this.unbindHoverEvent();

    this.startCallback();

    const y = this.promptEl.getBBox().height;

    this.promptBox
      .animate(600, 'quintIn')
      .translate(0, y)
      .after(() => {
        this.promptNextEvent();
        this.bindHoverEvent();

        setTimeout(() => {
          this.promptBoxEl.removeAttribute('transform');
          this.endCallback();
        }, 500);
      });
  }

  bindPromptNextEvent(event) {
    this.promptNextEvent = event;
    this.createPromptNextEvent();
  }

  onStartCallback(callback) {
    this.startCallback = callback;
  }

  onEndCallBack(callback) {
    this.endCallback = callback;
  }

  // Minor Anims
  contactAnim() {
    const contact = document.querySelector('.section--contact');
    contact.classList.remove('is-hidden');

    anime.remove('.section--contact .col-6 > *');

    anime({
      targets: '.section--contact .col-6 *',
      opacity: [0, 1],
      translateY: ['50%', '0'],

      duration: 500,
      easing: 'easeOutQuart',
      delay: (t, i) => i * 60
    });
  }

  hideContact() {
    const contact = document.querySelector('.section--contact');
    contact.classList.add('is-hidden');
  }
}

export class SlideAnimator {
  constructor(targetContainer) {
    this.toAnimate = [];

    const target = document.querySelector(targetContainer);
    const childNodes = document.querySelectorAll(`${targetContainer} > *`);

    Array.from(childNodes).forEach(node => {
      const tag = node.tagName.toLowerCase();

      if (tag === 'span') return;
      if (tag === 'img' || tag === 'svg')
        this.toAnimate.push(this.wrapElement(node));
      else this.toAnimate.push(this.splitString(node));
    });

    this.animTargets = target.querySelectorAll('._animated');

    this.resetAnimation();
  }

  wrapElement(element) {
    const wrapper = document.createElement('div');

    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
    wrapper.classList.add('_animated');

    return Array.from(wrapper.childNodes);
  }

  splitString(element) {
    const parent = element.parentNode;
    const tag = element.tagName.toLowerCase();
    const wrapper = document.createElement(tag);
    wrapper.classList.add('_animated');

    const string = element.innerHTML;
    const split = string.split(' ');

    split.forEach(s => {
      const innerWrap = document.createElement('span');
      innerWrap.innerHTML = s;

      wrapper.appendChild(innerWrap);
    });

    parent.insertBefore(wrapper, element);
    parent.removeChild(element);

    return Array.from(wrapper.childNodes);
  }

  animateSlide() {
    anime.remove(this.toAnimate);

    anime({
      targets: this.toAnimate,
      translateY: el => [el.getBoundingClientRect().height + 40, 0],
      opacity: {
        value: [0, 1],
        duration: 1
      },

      duration: 800,
      easing: 'easeOutQuint',
      delay: (t, i) => i * 60
    });
  }

  resetAnimation() {
    anime({
      targets: this.toAnimate,
      opacity: 0,
      duration: 1,
      easing: 'linear'
    });
  }
}
