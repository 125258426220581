export default class BackToTop {
  constructor(callback) {
    this.button = document.querySelector('.prompt-to-top');

    this.button.addEventListener('click', callback);

    window.addEventListener('scroll', () => {
      if (window.scrollY == 0) this.button.style.opacity = 0;
      else this.button.style.opacity = 1;
    });
  }

  setBlue() {
    this.button.classList.remove('_color--yellow');
  }

  setYellow() {
    this.button.classList.add('_color--yellow');
  }
}
