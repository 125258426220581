import 'core-js/modules/es6.promise';
import 'core-js/modules/es6.array.from';
import 'core-js/modules/es6.array.find-index';
import './polyfills/nodeListForEach';

import Loader from './components/loader';
import Fullpage from './components/fullpage';
import Modals from './components/modals';
import BackToTop from './components/backToTop';
import Accordion from './components/accordion';
import { Animator, SlideAnimator } from './components/animator';

import { isMobile, scrollTo } from './utils';

const loader = new Loader();
const modals = new Modals();

loader.waitDOMLoaded().then(() => activatePage());

function activatePage() {
  // to display video teaser after load page
  // if ( $('body').hasClass('page-home') ) {
  //   var j = jQuery.noConflict();
  //   $('#video-teaser').modal('show');
  // }

  // to delay 'container animation' css after page is loaded
  setTimeout(() => {
    $(".container-title").addClass("container-animation");
  }, 1500);

  enableMobileFPButton();

  new Accordion();

  return;

  if (!isMobile()) {
    const fullpage = new Fullpage();
    // const animator = new Animator();

    const backtotop = new BackToTop(() => fullpage.goTo(0));

    fullpage.bindCallback(0, () => backtotop.setBlue());
    fullpage.bindCallback(1, () => backtotop.setYellow());
    fullpage.bindCallback(2, () => backtotop.setYellow());
    fullpage.bindCallback(3, () => backtotop.setBlue());
    fullpage.bindCallback(4, () => backtotop.setBlue());
    fullpage.bindCallback(5, () => backtotop.setYellow());
    fullpage.bindCallback(6, () => backtotop.setBlue());

    // animator.onStartCallback(() => fullpage.toggleExec('pause'));
    // animator.onEndCallBack(() => fullpage.toggleExec('unpause'));

    // bind callbacks for section 2
    let hasOpened = false;
    fullpage.bindCallback(1, () => {
      // bind animation to execute before switching
      // animator.bindPromptNextEvent(() => fullpage.goToNext());
    });

    // bind callbacks for section 5
    // fullpage.bindCallback(4, () => {
    //   // toggle registration once
    //   if (!hasOpened) {
    //     hasOpened = true;
    //     modals.toggleRegistration();
    //   }
    // });

    // fullpage.setStopPoint(1, 'down');

    // const nextButtonAnimated = document.querySelector('.prompt--next');
    // nextButtonAnimated.addEventListener('click', () => {
    //   animator.promptNextEvent = () => fullpage.goToNext();
    //   animator.animateSlide();
    // });

    const nextButtonMini = document.querySelectorAll('.prompt--next--minimal');
    nextButtonMini.forEach(button =>
      button.addEventListener('click', () => fullpage.goToNext())
    );

    // animated sections
    // const textToAnimate = [1, 2, 5];
    // const slideAnimators = new Array(textToAnimate.length);

    // bind animation to each section
    // textToAnimate.forEach((section, index) => {
    //   const target = `.section[data-section-index="${section}"] .section--content`;
    //   slideAnimators[index] = new SlideAnimator(target);
    //   const current = slideAnimators[index];

    //   fullpage.bindCallback(section - 1, () => current.animateSlide());
    // });

    // reset animation on slide
    // fullpage.onEverySlide(() =>
    //   slideAnimators.forEach(anim => {
    //     anim.resetAnimation();
    //     animator.hideContact();
    //   })
    // );

    if (window.location.hash) fullpage.setStart();

    // // animate current section slide
    // const toAnimate = textToAnimate.findIndex(
    //   item => item === parseInt(fullpage.currentIndex) + 1
    // );
    // if (slideAnimators[toAnimate])
    //   setTimeout(() => slideAnimators[toAnimate].animateSlide(), 1700);
    // if (toAnimate == 5) animator.contactAnim();

    // because i'm lazy
    // fullpage.bindCallback(4, () => animator.contactAnim());
  } else {
    new BackToTop(() => scrollTo('.section:nth-child(1)', 500));

    const nextButtonAnimated = document.querySelector('.prompt--next');
    const nextButtonMini = document.querySelectorAll('.prompt--next--minimal');

    nextButtonAnimated.addEventListener('click', () =>
      scrollTo('.section:nth-child(3)', 500)
    );
    nextButtonMini[0].addEventListener('click', () =>
      scrollTo('.section:nth-child(2)', 500)
    );
    nextButtonMini[1].addEventListener('click', () =>
      scrollTo('.section:nth-child(5)', 500)
    );
  }
}


function enableMobileFPButton() {
  var animating = false;
  // Inital text for button
  $(".carousel-control-prev").each(function(){
    let firstFloorplanType = $(this).attr('href');
    let leftButtonText = $(this).find('.carousel-button-text');
    let rightButtonText = $(this).next().find('.carousel-button-text');
    
    let getActiveElem = $(firstFloorplanType).find('.carousel-item.active'); // Get current active view
    let getLeftText = $(getActiveElem).find('.carousel-control-left').text(); // Get current left text
    let getRightText = $(getActiveElem).find('.carousel-control-right').text(); // Get current right text
    leftButtonText.empty().append(getLeftText);
    rightButtonText.empty().append(getRightText);
  });



  // Button Left - Append Floorplan type text to button
  $(".carousel-control-prev").click(function() {
    var findFloorplanType = $(this).attr('href');
    var leftButtonText = $(this).find('.carousel-button-text');
    var rightButtonText = $(this).next().find('.carousel-button-text');
    
    // Wait slide animation to finish
    if ( !animating ){
      animating = true;
      setTimeout(() => {
        var getActiveElem = $(findFloorplanType).find('.carousel-item.active'); // Get current active view
        var getLeftText = $(getActiveElem).find('.carousel-control-left').text(); // Get current left text
        var getRightText = $(getActiveElem).find('.carousel-control-right').text(); // Get current right text
        leftButtonText.empty().append(getLeftText);
        rightButtonText.empty().append(getRightText);
        animating = false;
      }, 700);
    }
  })

  // Button Right - Append Floorplan type text to button
  $(".carousel-control-next").click(function() {
    var findFloorplanType = $(this).attr('href');
    var leftButtonText = $(this).prev().find('.carousel-button-text');
    var rightButtonText = $(this).find('.carousel-button-text');
    
    // Wait slide animation to finish
    if ( !animating ){
      animating = true;
      setTimeout(() => {
        var getActiveElem = $(findFloorplanType).find('.carousel-item.active'); // Get current active view
        var getLeftText = $(getActiveElem).find('.carousel-control-left').text(); // Get current left text
        var getRightText = $(getActiveElem).find('.carousel-control-right').text(); // Get current right text
        leftButtonText.empty().append(getLeftText);
        rightButtonText.empty().append(getRightText);
        animating = false;
      }, 700);
    }
  })

}

/* Floorplan  */
const buttons = document.querySelectorAll('.floorplan-button');

// for(var i = 0; i < buttons.length; i++) {
//   buttons[i].addEventListener('click', () => {
//     console.log('click', "Hi Im Button");
//   });
// }

buttons.forEach(button => {
  button.addEventListener('click', () => {
    toggleFloorPlan(button);
    toggleButtons(button);
    toggleConveniences(button);
  });
})

function toggleFloorPlan(button) {
  // get all floorplans
  const floorplans = document.querySelectorAll('.floorplan-carousel');
  // remove "active" class from all floorplans, essentially hiding them
  floorplans.forEach(plan => plan.classList.remove('active'));

  // getting which floorplan to show
  const showFloorplan = button.getAttribute('data-floorplan');
  // selecting the correct floorplan to be shown
  // '.floorplan-carousel[data-floorplan="' + showFloorplan + '"]'
  const floorplanToShow = document.querySelector(`.floorplan-carousel[data-floorplan="${showFloorplan}"]`);
  // adding "active" class to floorplan to show
  floorplanToShow.classList.add('active');
}

function toggleButtons(button) {
  // remove "active" class from all buttons, essentially hiding them  
  buttons.forEach(button => button.classList.remove('active'));
  // adding "active" class to current active button
  button.classList.add('active');
}

function toggleConveniences(button) {
  // get all conveniences carousel item
  const floorplans = document.querySelectorAll('.conveniences-carousel');
  // remove "active" class from all get conveniences carousel item, essentially hiding them
  floorplans.forEach(plan => plan.classList.remove('active'));

  // getting which floorplan to show
  // const showFloorplan = button.getAttribute('data-floorplan');
}