export default class Accordion {
  constructor() {
    this.getElements();
    this.bindEvents();
  }

  getElements() {
    this.accordion = document.querySelector('.vert--accordion');
    this.panels = this.accordion.querySelectorAll('.accordion--panel');
  }

  bindEvents() {
    this.panels.forEach(panel => {
      panel.addEventListener('mouseenter', e =>
        this.hoverEvent(e.currentTarget)
      );
    });

    this.accordion.addEventListener('mouseleave', this.leaveEvent.bind(this));
  }

  hoverEvent(target) {
    this.panels.forEach(panel => {
      panel.classList.add('panel--inactive');
      panel.classList.remove('panel--active');
    });

    target.classList.remove('panel--inactive');
    target.classList.add('panel--active');
  }

  leaveEvent() {
    this.panels.forEach(panel =>
      panel.classList.remove('panel--active', 'panel--inactive')
    );
  }
}
