import anime from 'animejs';
import _random from 'lodash/random';

export default class loader {
  constructor() {
    const loader = document.querySelector('.loader');
    const progress = loader.querySelector('svg path');
    const mask = loader.querySelector('svg #mask rect');

    const finalVal = 120;

    document.body.style.overflow = 'hidden';

    anime({
      targets: progress,
      translateX: '-26.6px',

      loop: true,
      duration: 1200,
      easing: 'linear'
    });

    anime({
      targets: mask,
      width: [0, finalVal * _random(0.3, 0.7)],

      duration: 3000,
      easing: 'linear'
    });

    this.waitDOMLoaded().then(() => {
      anime.remove(mask);

      anime
        .timeline()
        .add({
          targets: mask,
          width: finalVal,
          duration: 800,
          easing: 'easeInOutQuart'
        })
        .add({
          targets: '.loader--svg',
          width: 0,
          duration: 700,
          easing: 'easeInOutQuart'
        })
        .add({
          targets: '.loader',
          opacity: 0,
          duration: 600,
          easing: 'easeInOutQuart',

          complete: () => {
            loader.parentNode.removeChild(loader);
            document.body.style.overflow = '';
          }
        });
    });
  }

  waitDOMLoaded() {
    return new Promise(resolve =>
      window.addEventListener('load', () => resolve())
    );
  }
}
